.mainContentContainer {
  display: flex;
  justify-content: space-between;
  gap: 48px;
}

.mainImageContainer > img{
  object-fit: contain;
  max-width: 100%;
  max-height: 800px;
}

.mainContentRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 65%;
  color: var(--section-main-text-color)
}

/* .emphasize {
  font-style: italic;
  font-weight: 600;
} */

.mainContentRight > h1 {
  font-size: 140px;
  font-weight: 800;
  margin: 10px 0;
}

.mainContentRight > h2 {
  font-size: 56px;
  line-height: 1.1em;
  font-weight: 500;
}

.mainContentRight > h3 {
  font-size: 32px;
  font-weight: 500;
}

.divider {
  border: none;
  height: 1px;
  background-color: #888888;
  margin: 6em 0;
}

.socialLinksContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons svg {
  height: 32px;
}

.icons svg path {
  transition: fill 0.3s ease;
}

.icons svg:hover path {
  fill: var(--navlink-hover-color);
}

.secondaryContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4%;
}

.secondaryContentContainer > p {
  font-size: 18px;
  line-height: 1.6em;
}

.btnToProjects {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 2px solid var(--section-main-text-color);
  border-radius: 40px;
  padding: 12px 62px;
  color: var(--section-main-text-color);
  font-size: 30px;
  transition: color 0.7s ease, background-color 0.7s ease;
}

.btnToProjects:hover {
  color: var(--section-tertiary-bg-color);
  background-color: var(--section-tertiary3-bg-color)
}

@media screen and (max-width: 1180px) {
  .mainContentContainer {
    flex-direction: column;
    align-items: center;
  }

  .mainImageContainer > img{
    object-fit: contain;
    max-width: 100%;
    max-height: 600px;
  }

  .mainContentRight {
    align-self: center;
    gap: 20px;
    width: 100%;
  }

  .mainContentRight > h1 {
    font-size: 56px;
  }

  .mainContentRight > h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1920px) {
  .secondaryContentContainer {
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  .btnToProjects {
    font-size: 18px;
    padding: 8px 18px;
  }
}
