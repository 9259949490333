.projectContainer {
  display : grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: auto;
  gap: 200px 40px;
  margin-top: 100px;
  color: var(--section-main-text-color);
}

@media screen and (max-width: 1180px) {
  .projectContainer {
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
}
