.experienceContentContainer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.timeline {
  align-self: flex-end;
}

.experienceContentRight {
  flex: 1;
  color: var(--section-main-text-color)
}

.experienceContentRight > p {
  margin-top: 100px;
  font-size: 18px;
  line-height: 1.6em;
}

@media screen and (max-width: 1180px) {
  .experienceContentContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .timeline {
    align-self: stretch;
  }
}
