.experienceItemContainer {
  display: flex;
  padding: 10px 0;
  gap: 20px;
  font-size: 32px;
  color: var(--section-main-text-color)
}

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 3;
}

.info > p, .date {
  font-size: 18px;
  line-height: 1.6em;
}

.info > h4, .date {
  font-weight: 500;
}

@media screen and (max-width: 375px) {
  .date, .experienceItemContainer {
    font-size: 24px;
  }
}
