.projectCardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

.projectImageContainer > img {
  object-fit: contain;
  max-width: 100%;
}

.projectName {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1em;
}

.projectCardContainer > p, .linkContainer, .techStack {
  font-size: 18px;
  line-height: 1.6em;
}

.techStack {
  font-weight: 500;
}

.linkContainer {
  display: flex;
  gap: 20px;
  text-decoration: underline;
}

.projectCardContainer:hover {
  transform: scale(1.05);
}
