.skillCardContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skillCardContainer > h4 {
  font-size: 32px;
  font-weight: 500;
}

.skillContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 24px;
}

.skillItem {
  font-size: 18px;
  line-height: 1.6em;
}
