@import url("./reset.css");
/* @import url("./typescale.css"); */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --navlink-primary-color: #1B1B1B;
  --navlink-hover-color: #a0a0a0;

  --section-main-bg-color: #D1D1D1;
  --section-main-text-color: #333333;
  --section-secondary-text-color: #E0E0E0;

  --section-secondary-bg-color: #DFDFDF;

  --section-tertiary-bg-color: #f1efef;
  --section-tertiary2-bg-color: #f7f7f7;
  --section-tertiary3-bg-color: #000000;
}

a {
  text-decoration: none;
}

a:visited {
  color: var(--navlink-primary-color);
}

a:hover {
  color: var(--navlink-hover-color)
}
