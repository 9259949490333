.footerContainer {
  display: flex;
  gap: 40px;
  color: var(--section-secondary-text-color);
}

.footerContainer h3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1em;
}

.footerContentContainer > p {
  font-size: 18px;
  line-height: 1.6em;
}

.footerContentLeft,
.footerContentRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 1.6em;
}

.icons svg {
  height: 18px;
  margin-right: 20px;
}

.icons svg path {
  fill: #FFFFFF;
}

.footerContentRight a {
  color: var(--section-secondary-text-color);
  text-decoration: underline;
}

.footerContentRight a:hover {
  color: var(--navlink-hover-color)
}

@media screen and (max-width: 1180px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
  }

  .footerContentLeft > h3 {
    font-size: 32px;
  }
}
