.paddingYxs {
  padding: 40px 0;
}

.paddingYs {
  padding: 140px 0;
}

.paddingYm {
  padding: 150px 0;
}

.paddingYl {
  padding: 200px 0;
}

.paddingYxs, .paddingYs, .paddingYm, .paddingYl {
  padding-left: 12%;
  padding-right: 12%;
}

.sectionColorMain {
  background-color: var(--section-main-bg-color);
}

.sectionColorSecondary {
  background-color: var(--section-secondary-bg-color);
}

.sectionColorTertiary {
  background-color: var(--section-tertiary-bg-color);
}

.sectionColorTertiary2 {
  background-color: var(--section-tertiary2-bg-color);
}

.sectionColorTertiary3 {
  background-color: var(--section-tertiary3-bg-color);
}

.section {
  overflow: hidden;
}

.section h1 {
  font-size: 72px;
  font-weight: 800;
  color: var(--section-main-text-color)
}

.section > div {
  opacity: 0;
  transform: translateX(30%);
  transition: all 1s ease;
}

.section > div.fadeToLeft {
  opacity: 1 ;
  transform: translateX(0);
}

@media screen and (max-width: 1920px) {
  .section h1 {
    font-size: 52px;
  }

  .paddingYxs, .paddingYs, .paddingYm, .paddingYl {
    padding: 40px 5%;
  }
}
