.scrollBtn {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  color: var(--navlink-primary-color);
  transition: color 0.7s ease;
}

.scrollBtn::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--navlink-primary-color);
  transition: all 0.3s ease-in-out;
}

.scrollBtn:hover {
  color: var(--navlink-hover-color)
}

.scrollBtn:hover::before {
  width: 100%;
  background-color: var(--navlink-hover-color);
}
