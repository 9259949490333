.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navLinkContainer {
  display: flex;
  gap: 20px;
}

.navLinkContainer > a, .dropDown > a{
  position: relative;
  text-decoration: none;
  color: var(--navlink-primary-color);
  transition: color 0.7s ease;
  font-size: 24px;
}

.navLinkContainer > a::before, .dropDown > a::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--navlink-primary-color);
  transition: all 0.3s ease-in-out;
}

.navLinkContainer > a:hover, .dropDown > a:hover{
  color: var(--navlink-hover-color);
}

.navLinkContainer > a:hover::before, .dropDown > a:hover::before{
  width: 100%;
  background-color: var(--navlink-hover-color);
}

@media screen and (max-width: 599px) {
  /* .navLinkContainer > a {
    display: none;
  } */

  .navbarContainer {
    flex-direction: column-reverse;
    gap: 20px;
  }


  /* .hamburger {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }

  .hamburgerBar {
    width: 100%;
    height: 3px;
    background: black;
    transition: all .3s ease;
  }

  .hamburger.open .hamburgerBar:nth-child(1) {
    transform: rotate(35deg) translateY(12px) translateX(6px);
  }

  .hamburger.open .hamburgerBar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .hamburgerBar:nth-child(3) {
    transform: rotate(-35deg) translateY(-12px) translateX(6px);
  }

  .dropDown {
    position: absolute;
    display: flex;
    gap: 12px;
    top: 60px;
    left: 0px;
  } */

}
