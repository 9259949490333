.skillsHeading {
  font-size: 72px;
  line-height: 1.05em;
  font-weight: 800;
  color: var(--section-main-text-color);
}

.skillsContentContainer {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "languages frameworks contact" "tools apis contact" "certs certs contact";
  gap: 75px 125px;
  margin-top: 100px;
}

.languages {
  grid-area: languages;
}

.frameworks {
  grid-area: frameworks;
}

.apis {
  grid-area: apis;
}

.tools {
  grid-area: tools;
}

.certs {
  grid-area: certs;
}

.contact {
  grid-area: contact;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  color: white;
  padding: 40px;
}

.contact > h4 {
  line-height: 1.1em;
  font-size: 32px;
  font-weight: 500;
}

@media screen and (max-width: 1180px) {
  .skillsContentContainer {
    display: flex;
    flex-direction: column;
  }

  .contact {
    padding-top: 100px;
  }
}

@media screen and (max-width: 1460px) {
  .contact > h4{
    font-size: 18px;
  }
}
